<template>
  <BaseForm
    @submit.prevent="login"
    :validation="v$"
    :message="bvMessage"
    :error="bvHasErrors"
    hideMandatory
  >
    <BaseRow noGutter>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.username"
          label="stp.login.form.label.username"
          placeholder="stp.login.form.placeholder.username"
          :validation="v$.username"
          :messages="bvShowErrors('username').messages"
          :error="bvShowErrors('username').hasMessages"
          :maxLength="null"
          hideMandatory
          floatingLabel
        />
      </BaseCol>

      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.password"
          label="stp.login.form.label.password"
          placeholder="stp.login.form.placeholder.password"
          :validation="v$.password"
          type="password"
          :messages="bvShowErrors('password').messages"
          :error="bvShowErrors('password').hasMessages"
          :maxLength="null"
          hideMandatory
          floatingLabel
        />
      </BaseCol>

      <BaseCol cols="12" class="text-right">
        <BaseText size="xs">
          <BaseLink :to="{ name: 'RequestNewPassword' }">
            {{ $t("stp.login.label.requestNewPassword") }}
          </BaseLink>
        </BaseText>
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton type="submit" label="stp.login.form.label.btn" fullWidth :loading="loading" />
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import useBackendValidation from "@use/useBackendValidation";
import { reactive, watch } from "vue";

export default {
  name: "LoginForm",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    backendValidation: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    // Initialize form values
    const form = reactive({
      username: null,
      password: null,
    });

    // Setup validation
    const rules = {
      username: ["required"],
      password: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    // Emit form values
    const login = () => {
      v$.value.$touch();
      if (v$.value.$error || props.loading) return;
      emit("login", formValues);
    };

    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();

    watch(props, () => {
      if (props.backendValidation) {
        updateValidation(props.backendValidation);
      }
    });

    return {
      v$,
      formValues,
      login,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
    };
  },
};
</script>
