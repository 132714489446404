<template>
  <div class="flex justify-content-center my-8">
    <div class="shadow-3 border-round p-4 w-22rem bg-white">
      <BaseRow :no-gutter="true" class="justify-content-between">
        <BaseCol class="flex flex-grow-1 align-items-center mb-3">
          <BaseHeadline size="6" class="text-primary" noMargin>
            {{ $t("stp.login.title") }}
          </BaseHeadline>
        </BaseCol>
        <BaseCol class="flex flex-grow-0 align-items-center align-content-end mb-3">
          <BaseButton
            iconLeft="pi pi-times"
            rounded
            text
            color="gc-color-danger"
            @click="goToStartPage"
          />
        </BaseCol>
      </BaseRow>

      <LoginForm
        @login="auth.callApi($event)"
        :loading="isLoading"
        :backendValidation="auth.backendValidation.value"
      />

      <BaseDivider />

      <div class="text-center">
        <BaseText size="xs">{{ $t("stp.login.label.createAccount") }}</BaseText>

        <BaseText size="xs">
          <BaseLink :to="{ name: 'Register' }">{{ $t("stp.login.label.register") }}</BaseLink>
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "./partials/LoginForm.vue";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";
import redirectRouteMenu from "@/modules/haw-components-hac/helpers/redirectRouteMenu";

export default {
  name: "Login",

  components: {
    LoginForm,
  },

  setup() {
    const { showSuccess } = useNotifications();

    const router = useRouter();
    const store = useStore();

    const auth = useApi({
      module: "usm",
      method: "login",
      onSuccess: async () => {
        showSuccess("stp.login.notify.success.summary", "stp.login.notify.success.detail");

        await store.dispatch("usm/authUser/setIsLoading", {
          isLoading: true,
        });

        await store.dispatch("usm/auth/saveToken", {
          accessToken: auth.docs.value.token.access_token,
          refreshToken: auth.docs.value.token.refresh_token,
        });

        await authUser.callApi();
      },
    });

    const authUser = useApi({
      module: "usm",
      method: "showAuthUser",
      onSuccess: async () => {
        await store.dispatch("usm/authUser/saveData", authUser.docs.value);

        await store.dispatch("usm/authUser/setIsLoading", {
          isLoading: false,
        });

        await pushAfterLogin();
      },
    });

    const pushAfterLogin = async () => {
      const data = redirectRouteMenu.getRedirectData();

      try {
        await store.dispatch("hac/expandableNavigation/closeAll");
        await store.dispatch("hac/expandableNavigation/expand", data.expandedNavigation);
        await router.push({ name: data.routeName });
      } catch {
        return;
      }
    };

    const isLoading = computed(() => {
      return auth.isLoading.value || authUser.isLoading.value;
    });

    return {
      auth,
      authUser,
      isLoading,
    };
  },

  methods: {
    async goToStartPage() {
      try {
        await this.$router.push({ name: "StartPage" });
      } catch {
        return;
      }
    },
  },
};
</script>
