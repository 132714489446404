import store from "@/store";

const getRedirectData = () => {
  let routeName;
  let expandedNavigation;

  const user = store.getters["usm/authUser/user"];
  const permissions = store.getters["usm/authUser/permissions"];

  if (!user) {
    routeName = "StartPage";
    expandedNavigation = "user";
  } else if (!user.teacher) {
    routeName = "MyDashboard";
    expandedNavigation = "user";
  } else if (permissions.includes("teacher.course_request.read")) {
    routeName = "CourseRequestsList";
    expandedNavigation = "teacher";
  } else if (permissions.includes("management.course_proposal.read")) {
    routeName = "ManagementCourseProposalsList";
    expandedNavigation = "management";
  } else {
    routeName = "CourseProposalCreate";
    expandedNavigation = "teacher";
  }

  return {
    routeName,
    expandedNavigation,
  };
};

export default {
  getRedirectData,
};
